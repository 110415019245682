<template>
  <div class="new-vgarden">
    <b-row class="d-flex justify-content-center" no-gutters>
      <!-- Liste des plantes -->
      <b-col
          v-if="vGardenCreated && vGardenCreated.vgarden_plants.length > 0"
          v-show="!isLastStep"
          cols="12"
          lg="6"
          :key="plantListKey">
        <app-collapse>
          <app-collapse-item ref="plantList" :isVisible="true" :title="'Vos plantes (' + plantCount +')'" @click="this.interactionsKey += 1">
            <div v-if="vGardenCreated">
              <JayaTooltip
                  position="bottom"
                  text="Estimation de l'emprise en sol des plantes ajoutées par rapport à la surface totale de la planche potagère. Attention, pour les arbres, la taille donnée correspond à celle de leur feuillage et pas de leur tronc."
                  title="En savoir plus">
                Remplissage de la planche potagère - {{ Math.round(plantsToVGardenRatio * 100) + '%' }}
              </JayaTooltip>
              <b-progress
                  v-if="plantsToVGardenRatio"
                  :value="plantsToVGardenRatio*100"
                  :variant="plantsToVGardenRatioColor"
                  max="100"/>
            </div>
            <b-tabs fill>
              <b-tab title="Liste" @click="setCollapseHeight(false)">
                <b-list-group flush>
                  <b-list-group-item
                      v-for="(plant, index) in vGardenPlants"
                      :key="`${index}`">
                    <b-row no-gutters>
                      <b-col class="text-truncate" cols="6">
                        <h5 class="mb-0 text-truncate">{{ plant.garden_plant.plant.common_name }}</h5>
                        <div class="text-italic text-truncate"> {{ plant.garden_plant.plant.scientific_name }}</div>
                      </b-col>
                      <b-col class="d-flex align-items-center justify-content-end">
                        <strong> x{{ plant.quantity }} </strong>
                        <b-button
                            class="ml-1 p-50"
                            pill
                            size="sm"
                            variant="primary"
                            @click="showAddPlant(plant.garden_plant.plant)">
                          <feather-icon
                              icon="PlusIcon"
                              size="16"/>
                        </b-button>
                        <b-button
                            class="ml-1 p-50"
                            pill
                            size="sm"
                            variant="danger"
                            @click="showDeletePlant(plant)">
                          <feather-icon
                              icon="TrashIcon"
                              size="16"/>
                        </b-button>
                      </b-col>
                      <b-col class="d-flex align-items-center justify-content-end">
                        <PlantScore
                            :received_interactions="plant.garden_plant.plant.received_interactions"
                            :vGarden="vGardenCreated"
                            style="height: 40px;"/>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-tab>
              <b-tab title="Interactions" @click="setCollapseHeight(true)">
                <VGardenInteractions :plantList="vGardenPlants" :vGarden="vGardenCreated" :key="interactionsKey"/>
              </b-tab>
              <feather-icon
                  v-if="vGardenPlants.length > 1 && !seenInter"
                  class="info-icon"
                  icon="InfoIcon"
                  size="16"/>
            </b-tabs>
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col :lg="isLastStep ? '12' : '6'" cols="12">
        <form-wizard
            ref="wizard"
            :subtitle="null"
            :title="null"
            backButtonText="Précédent"
            class="p-0 h-full pb-2"
            color="var(--primary)"
            errorColor="(--danger)"
            finishButtonText="Valider"
            nextButtonText="Suivant"
            stepSize="xs"
            @on-complete="validatePositionsPrompt = true"
            v-on:on-change="updatePlantSearch">

          <!-- Informations générales : Vgarden created -->
          <tab-content :before-change="validateInfo">
            <VGardenInfo ref="garden_info" initialSetUp v-on:updateInfo="updateVGarden"/>
          </tab-content>

          <!-- 1ere étape: choix des plantes 1/2 -->
          <tab-content>
            <div id="plantSearchTab" class="plantSearchTab overflow-x-hidden" v-on:scroll="scroll">
              <TextBlock title="Choix des plantes"/>
              <h6 class="mb-1"> Recherchez et cliquez les plantes que vous souhaitez ajouter à votre planche potagère. </h6>
              <PlantSearch :key="plantSearchKey" ref="plantSearch" :vGarden="vGardenCreated" class="my-2" clickToGiveId
                  forceFilterPopup v-on:newSelection="showAddPlant"/>
            </div>
          </tab-content>

          <!-- 1ere étape: conseils 2/2 -->
          <tab-content :before-change="validateContent">
            <div class="plantSearchTab overflow-x-hidden" v-on:scroll="scroll">
              <TextBlock title="Nos conseils"/>
              <h6 class="mb-1"> Voici quelques plantes qui s'adapteront bien à votre jardin </h6>
              <PlantSearch :key="plantSearchAdviceKey + 1" ref="plantAdviceSearch" :activeFilter="false"
                  :vGarden="vGardenCreated" class="my-2" clickToGiveId getAdvice
                  v-on:newSelection="showAddPlant"/>
            </div>
          </tab-content>

          <!-- 2eme étape : locations-->
          <tab-content>
            <VGardenLocations v-if="isLastStep" :key="locationsKey"  :vgarden="vGardenCreated" initialSetUp @plant-moved="plantMoved=true" @update-vgarden="getVGardenUpdate"/>
          </tab-content>
          <jaya-divider class="m-1" color="transparent"/>
        </form-wizard>
      </b-col>
    </b-row>

    <b-modal
        v-model="validatePositionsPrompt"
        :ok-title="this.plantMoved ? 'Valider' : 'Continuer sans positionner' "
        cancel-title="Annuler"
        centered
        title="Valider la position des plantes"
        variant="primary"
        @ok="goToVGarden">
      <h3> {{ this.plantMoved ? 'Valider la position des plantes ?' : 'Aucune plante positionnée !' }} </h3>
      <span class="text-center">
        {{
          this.plantMoved ? "La position des plantes de votre jardin vous convient-elle ?" : "Vous avez la possibilité de positionner les plantes de votre jardin. Pour cela il vous suffit de les sélectionner et de les faire glisser."
        }}
      </span>
    </b-modal>

    <VGPlantAdd ref="addPlantPrompt" :addingPlant="addingPlant" :vGarden="vGardenCreated" @plant-added="onPlantAdded()"></VGPlantAdd>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="deletePlantsPrompt"
          :ok-disabled="invalid"
          cancel-title="Annuler"
          centered
          ok-title="Supprimer"
          title="Suppression de plante(s)"
          variant="danger"
          @ok="deletePlants()">
        <div class="font-medium-3 text-center w-100 p-50 pt-0"> Nombre de plants à supprimer ?</div>
        <validation-provider v-slot="{errors}" :rules="{min_value:1, integer:null}">
          <div class="w-100 d-flex justify-content-center">
            <b-form-input
                v-model="plantNumber"
                :max="maxPlantNumber"
                class="text-center"
                min="1"
                step="1"
                style="width: 100px;"
                type="number"/>
          </div>
          <b-form-text class="text-danger text-center">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import PlantSearch from '@/components/plants/PlantSearch.vue'
import VGardenInfo from '@/components/vgarden/VGardenInfo.vue'
import VGardenInteractions from '@/components/vgarden/VGardenInteractions.vue'
import VGardenLocations from '@/components/vgarden/VGardenLocations.vue'
import PlantScore from "@/components/plants/PlantScore.vue"
import _ from 'lodash'
import {
  BButton,
  BCol,
  BFormInput,
  BFormText,
  BListGroup,
  BListGroupItem,
  BModal,
  BProgress,
  BRow,
  BTab,
  BTabs,
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, max_value, min_value} from 'vee-validate/dist/rules'
import VGPlantAdd from "@/components/plants/VGPlantAdd";

extend('max_value', {...max_value, message: "Impossible d'ajouter plus de {max} plantes"});
extend('min_value', {...min_value, message: "Impossible d'ajouter moins de {min} plante"});
extend('integer', {...integer, message: "Le nombre de plantes doit être un nombre entier !"});

export default {
  name: 'NewVGarden',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BModal,
    BFormInput,
    BProgress,
    BListGroup,
    BListGroupItem,
    BFormText,
    AppCollapse,
    AppCollapseItem,
    FormWizard,
    TabContent,
    TextBlock,
    PlantSearch,
    VGardenInfo,
    VGardenInteractions,
    VGardenLocations,
    PlantScore,
    ValidationObserver,
    ValidationProvider,
    VGPlantAdd,
  },
  data() {
    return {
      vGardenCreated: null,
      activePlant: {},
      vGardenInfo: {},
      vGardenModel: {plants: []},
      isMounted: false, //used to be able to check if $refs are created
      validatePositions: false,
      validatePositionsPrompt: false,
      plantSearchKey: 1,
      plantSearchAdviceKey: 2,
      plantMoved: false,
      seenInter: false,
      addPlantNumber: 1,
      addPlantPrompt: false,
      addPlantType: 'S',
      addPlantAreaLength: null,
      addPlantAreaWidth: null,
      locationsKey: 0,
      addPlantAreaSpacingX: null,
      addPlantAreaSpacingY: null,
      maxPlantNumber: 100,
      addPlantsPrompt: false,
      deletePlantsPrompt: false,
      interactionsKey: 0,
      addingPlant: {},
      plantListKey: 0,
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    vGardenPlants() {
      //transforms vgardenplants into list into of 'species' with quantities
      if (this.vGardenCreated) {
        let plantsForInteractions = []
        for (var i = 0; i < this.vGardenCreated.vgarden_plants.length; i++) {
          let plantSpecies = this.vGardenCreated.vgarden_plants[i].garden_plant.plant
          // make sure specific 'species' is not already in list in the making
          if (!plantsForInteractions.filter(item => {
            return item.id === plantSpecies.id
          }).length) {
            let plantsInstances = this.vGardenCreated.vgarden_plants.filter(item => {
              return item.garden_plant.plant.id === plantSpecies.id
            })

            let quantity = 0
            plantsInstances.forEach((plant) => {
              quantity += plant.item_number
            })

            let plantValue = {
              id: plantSpecies.id,
              garden_plant: {plant: plantSpecies, id: plantSpecies.id},
              quantity: quantity
            }
            plantsForInteractions.push(plantValue)
          }
        }
        return _.orderBy(plantsForInteractions, 'garden_plant.plant.common_name')
      }
      return false
    },
    plantSurface() {
      let result = 0
      for (var plant of this.vGardenPlants) {
        let plantRadius = plant.garden_plant.plant.jaya_infos ? plant.garden_plant.plant.jaya_infos.planting_radius : 0.05
        result += 3.14 * plantRadius * plantRadius * plant.quantity
      }
      return result
    },
    vGardenSurface() {
      if (this.vGardenCreated && this.vGardenCreated.width && this.vGardenCreated.length) {
        return (this.vGardenCreated.width * this.vGardenCreated.length)
      }
      return 0
    },
    plantsToVGardenRatio() {
      if (this.vGardenCreated && this.vGardenCreated.width > 0 && this.vGardenCreated.length > 0 && this.vGardenSurface > 0 && this.vGardenPlants.length > 0) {
        return this.plantSurface / this.vGardenSurface
      }
      return null
    },
    isLastStep() {
      return (this.isMounted && this.vGardenCreated && this.$refs.wizard.isLastStep)
    },
    plantsToVGardenRatioColor() {
      if (this.plantsToVGardenRatio > 1) {
        return 'danger'
      } else if (this.plantsToVGardenRatio < 0.75) {
        return 'secondary'
      }
      return 'primary'
    },
    plantCount() {
      let result = 0
      for (var i = 0; i < this.vGardenPlants.length; i++) {
        result += parseInt(this.vGardenPlants[i].quantity)
      }
      return result
    }
  },
  methods: {
    scroll(event) {
      if (event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
        this.$refs.plantSearch.scroll(event)
      }
    },
    setCollapseHeight(seeInter = false) {
      setTimeout(() => {
        // Make sure plant list is open in large screens
        if (this.$refs.plantList.maxHeight == '0px' && this.$store.state.app.windowWidth > 991) {
          this.$refs.plantList.toggleContent()
        }
        this.interactionsKey += 1
      }, 100)
      if (seeInter) {
        this.seenInter = true
      }
    },

    validateContent() {
      if (this.vGardenCreated && this.vGardenCreated.vgarden_plants.length <= 0) {
        this.$notify({
          time: 4000,
          title: "Attention",
          color: "secondary",
          text: "Il faut au moins une plante dans la planche potagère."
        })
        return false
      }
      return true
    },
    validateInfo() {
      if (!this.vGardenCreated) {
        this.vGardenInfo = {
          name: this.$refs.garden_info.name,
          garden: this.$refs.garden_info.associatedGarden.id,
          outside_vgarden: this.$refs.garden_info.situation !== 'inside',
          exposition: this.$refs.garden_info.exposition,
          soil_type: this.$refs.garden_info.soil_type ? this.$refs.garden_info.soil_type : 'UN',
          organic_richness: this.$refs.garden_info.organic_richness ? this.$refs.garden_info.organic_richness : 'UN',
          width: this.$refs.garden_info.width,
          length: this.$refs.garden_info.length
        }

        if (this.$refs.garden_info.situation === 'inside') {
          this.vGardenInfo['outside_vgarden'] = false
        } else if (this.$refs.garden_info.situation === 'outside') {
          this.vGardenInfo['outside_vgarden'] = true
        } else {
          this.vGardenInfo['outside_vgarden'] = null
        }

        if (this.vGardenInfo.name === '') {
          this.$notify({
            title: "Attention",
            color: "danger",
            text: "Il faut un nom à votre planche potagère."
          })
          return false
        } else if (!(this.vGardenInfo.length > 0) || !(this.vGardenInfo.width > 0)) {
          this.$notify({
            title: "Attention",
            color: "danger",
            text: "La taille de la planche potagère ne peut pas être nulle"
          })
          return false
        } else if (this.vGardenInfo.length > 20 || this.vGardenInfo.width > 20) {
          this.$notify({
            title: "Attention",
            color: "danger",
            text: "La taille de la planche potagère ne peut pas dépasser 20m de côté."
          })
          return false
        } else {
          //create vGarden
          this.$loading(true)
          this.$store.dispatch("garden/addVGarden", this.vGardenInfo)
              .then(response => {
                this.vGardenCreated = response.data
                this.$notify({
                  time: 1000,
                  color: "primary",
                  title: "Planche potagère créée",
                })
                this.plantSearchKey = this.plantSearchKey + 2
                this.$loading(false)
              })
              .catch(() => {
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Erreur",
                  text: "Une erreur est survenue lors de l'ajout de la planche potagère, veuillez réessayer plus tard"
                })
                this.vGardenError
                this.$loading(false)
              })
          return true
        }
      } else {
        return true
      }
    },
    showAddPlant(value) {
      this.plantNumber = 1
      if (this.vGardenCreated) {
        this.$store.dispatch("plant/get", {id: value.id})
            .then((response) => {
              this.addingPlant = response.data
              this.$refs.addPlantPrompt.addPlantPrompt = true
            })
      }
    },
    onPlantAdded() {
      this.getVGardenUpdate()
      this.$refs.addPlantPrompt.addPlantPrompt = false
      this.plantListKey = this.plantListKey + 1
    },
    getVGardenUpdate() {
      this.$store.dispatch("garden/getVGarden", {index: this.vGardenCreated.id})
          .then(response => {
            this.$store.commit('garden/UPDATE_VGARDEN', response.data)
            this.vGardenCreated = response.data
            this.setCollapseHeight()
            this.interactionsKey += 1
            this.locationsKey = this.locationsKey + 1
          })
    },
    showDeletePlant(value) {
      this.plantNumber = 1
      if (this.vGardenCreated) {
        this.activePlant = value
        this.maxPlantNumber = this.vGardenCreated.vgarden_plants.filter(item => item.garden_plant.plant.scientific_name === this.activePlant.garden_plant.plant.scientific_name).length
        this.deletePlantsPrompt = true
      }
    },
    deletePlants() {
      let plantToDelete = this.vGardenCreated.vgarden_plants.find(item => item.garden_plant.plant.scientific_name === this.activePlant.garden_plant.plant.scientific_name)
      if (this.plantNumber > 0 && this.plantNumber <= this.maxPlantNumber) {
        if (this.plantNumber > 1) {
          this.$store.dispatch("garden/deleteVGardenPlant", {
            index: plantToDelete.id,
            gardenPlantId: plantToDelete.garden_plant.id,
            vgardenId: this.vGardenCreated.id
          })
              .then(() => {
                this.plantNumber = this.plantNumber - 1
                this.deletePlants()
              })
        } else {
          this.$store.dispatch("garden/deleteVGardenPlant", {
            index: plantToDelete.id,
            gardenPlantId: plantToDelete.garden_plant.id,
            vgardenId: this.vGardenCreated.id
          })
              .then(() => {
                this.$store.dispatch("garden/getVGarden", {index: this.vGardenCreated.id})
                    .then(response => {
                      this.$store.commit('garden/UPDATE_VGARDEN', response.data)
                      this.vGardenCreated = response.data
                      this.setCollapseHeight()
                      this.interactionsKey += 1
                    })
              })
        }
      }
    },
    updateVGarden() {
      if (this.vGardenCreated) {
        let payload = {
          id: this.vGardenCreated.id,
          name: this.$refs.garden_info.name,
          garden: this.$refs.garden_info.associatedGarden.id,
          outside_vgarden: this.$refs.garden_info.situation !== 'inside',
          exposition: this.$refs.garden_info.exposition,
          soil_type: this.$refs.garden_info.soil_type ? this.$refs.garden_info.soil_type : 'UN',
          organic_richness: this.$refs.garden_info.organic_richness ? this.$refs.garden_info.organic_richness : 'UN',
          width: this.$refs.garden_info.width,
          length: this.$refs.garden_info.length
        }

        if (this.$refs.garden_info.situation === 'inside') {
          payload['outside_vgarden'] = false
        } else if (this.$refs.garden_info.situation === 'outside') {
          payload['outside_vgarden'] = true
        } else {
          payload['outside_vgarden'] = null
        }

        this.$store.dispatch('garden/updateVGarden', payload)
            .then(response => {
              this.vGardenCreated = response.data
              this.interactionsKey += 1
            })
      }
    },
    goToVGarden() {
      if (this.vGardenCreated) {
        this.$router.push('/potager/' + this.vGardenCreated.id)
      }
    },

    vGardenError() {
      //if error go back to first step and delete vGarden
      if (this.vGardenCreated) {
        this.$store.dispatch('garden/deleteVGarden', this.vGardenCreated.id)
        this.vGardenCreated = null
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Une erreur est survenue lors de la suppression de la planche potagère, veuillez recommencer"
        })
      }
      this.$refs.wizard.reset
    },
    updatePlantSearch(startTab, endTab) {
      startTab
      if (endTab === 1) {
        this.plantSearchKey = this.plantSearchKey + 2
      } else if (endTab === 2) {
        this.plantSearchAdviceKey = this.plantSearchAdviceKey + 2
      }
    }
  },
  watch: {
    plantList() {
      if (this.$refs.wizard) {
        let index = this.$refs.wizard.activeTabIndex
        this.updatePlantSearch(0, index)
      }
    }
  }
}
</script>

<style lang="scss">
.new-vgarden {
  max-height: 50%;

  .button-type {
    height: 90px;
    width: 90px;
    border-radius: 20px;
    border: 2px solid var(--primary);
  }

  .wizard-header {
    padding: 0;
  }

  .wizard-tab-content {
    padding-left: 0;
    padding-right: 0;
  }

  .plantSearchTab {
    max-height: calc(100vh - 220px);
    overflow: auto;
  }

  .wizard-card-footer {
    position: sticky;
    @media (min-width: 576px) {
    }
  }

  .wizard-footer-right {
    @media (max-width: 576px) {
      position: fixed;
      bottom: 20px;
      right: 15px;
    }
  }

  .wizard-footer-left {
    @media (max-width: 576px) {
      position: fixed;
      bottom: 20px;
      left: 15px;
    }
  }

  .info-icon {
    position: absolute;
    top: -25px;
    right: 5%;
    color: var(--primary);

  }
}
</style>
