<template>
  <div class="new-vgarden">
    <JayaTitle v-if="design==null" classType="page-title" clickable titleName="Créer une planche potagère" @titleClicked="designHelpPopUp = true"/>
    <div v-if="design==null" class="max-w-md mr-auto ml-auto mt-3">
      <div class="d-flex justify-content-center mb-1">
        <b-button
            variant="primary"
            @click="design = true">
          <img alt="Potager à designer" src="@/assets/images/icons/other/design.svg" style="width: 300px;"><br>
          Concevoir sa planche potagère
        </b-button>
      </div>
      <div class="d-flex justify-content-center ">
        <b-button
            disabled
            variant="dark"
            @click="design = false">
          <img alt="Exemple de modèle de potager" src="@/assets/images/icons/other/premade_disabled.svg" style="width: 300px;"><br>
          Choisir un modèle
        </b-button>
      </div>
    </div>
    <NewVGardenDesign v-if="design"/>
    <div v-if="design===false">
      <!-- TODO: add premade choices for vgardens -->
    </div>

    <b-modal
        v-model="designHelpPopUp"
        centered
        class="help-popup"
        hide-footer
        scrollable
        title="La conception d'un potager">
      <VGardenDesignHelp/>
    </b-modal>
  </div>
</template>

<script>
import NewVGardenDesign from '@/components/vgarden/NewVGardenDesign.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import VGardenDesignHelp from '@/components/vgarden/VGardenDesignHelp.vue'
import JayaTitle from '@/components/global/JayaTitle.vue'
import {BButton, BModal} from "bootstrap-vue";

export default {
  name: 'NewVGarden',
  components: {
    BButton,
    BModal,
    NewVGardenDesign,
    TextBlock,
    VGardenDesignHelp,
    JayaTitle
  },
  data() {
    return {
      design: null,
      designHelpPopUp: false,
    }
  }
}
</script>
